<div class="header-container">
    <header class="mx-5 | 3xl:container 3xl:mx-auto">
        <div>
            <app-logo />
        </div>

        <div class="ml-auto border-padding-box">
            <app-language-switch />
        </div>

        <div class="border-padding-box">
            <app-profile type="salesperson" />
        </div>

        <div class="border-padding-box">
            <button type="button" class="block" (click)="onLogoutClick()">
                <app-icon name="material-symbols:logout-rounded" class="icon-5 icon-gray-700"></app-icon>
            </button>
        </div>
    </header>
</div>

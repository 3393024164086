<div class="min-h-screen grid grid-rows-[auto,1fr,auto]">
    <div class="sticky top-0 z-[100]">
        <app-header />
    </div>

    <section class="py-6 md:py-12 mx-5 lg:mx-20| 3xl:container 3xl:mx-auto">
        <app-statuses-container>
            <app-ship />
        </app-statuses-container>
        <router-outlet />
    </section>

    <app-footer />
</div>

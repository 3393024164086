import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { filter, take, tap } from 'rxjs';
import { InvoiceMailsFacade } from '../facades/invoice-mails.facade';

export function invoicesMailsLoaded(): CanActivateFn {
    return () => {
        const invoiceMailsFacade = inject(InvoiceMailsFacade);

        return invoiceMailsFacade.loaded$.pipe(
            tap((loaded) => {
                if (loaded === null) {
                    invoiceMailsFacade.getInvoiceMails();
                }
            }),
            filter((loaded): loaded is boolean => loaded !== null),
            take(1),
        );
    };
}

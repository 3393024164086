import { MenuItem } from '../models';

export const menu: MenuItem[] = [
    {
        name: 'header.nav.dashboard',
        route: '/dashboard',
        feature: 'nav.dashboard',
    },
    {
        name: 'header.nav.cards',
        route: '/cards',
        feature: 'nav.cards',
    },
    {
        name: 'header.nav.operations',
        route: '/operations',
        feature: 'nav.operations',
    },
    {
        name: 'header.nav.invoices',
        route: '/invoices',
        feature: 'nav.invoices',
    },
    {
        name: 'header.nav.balance',
        route: '/balance',
        feature: 'nav.balance',
    },
    {
        name: 'header.nav.gas-stations',
        route: '/gas-stations',
        feature: 'nav.gas-stations',
    },
];

import { Component } from '@angular/core';
import { footerEnvironment } from '@footer';
import { options } from '@options';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
    readonly footerLinks = footerEnvironment.footerLinks;
    readonly shopLinks = footerEnvironment.shopLinks;
    readonly contact = footerEnvironment.contact;
    readonly logo = `${options.imagesPath}${options.footerImage}`;
}

import { Injectable, inject } from '@angular/core';
import { AccountFacade, AccountMessagesService } from '@corelabs/angular-account';
import { MessageService } from '@corelabs/angular-messages';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, tap } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class MessagesBusService {
    private readonly messageService = inject(MessageService);
    private readonly accountMessagesService = inject(AccountMessagesService);
    private readonly accountFacade = inject(AccountFacade);
    private readonly translateService = inject(TranslateService);
    private readonly subscriptions$ = new Subscription();

    constructor() {
        this.subscriptions$.add(
            this.accountFacade.error$
                .pipe(
                    tap((error) => {
                        let message = '';
                        if (error.message === 'Invalid credentials.') {
                            message = 'cl.account.login-invalid-credentials';
                        } else if (error.message !== '') {
                            message = `cl.account.${error.message}`;
                        } else {
                            message = `cl.account.unknown`;
                        }

                        this.messageService.add(this.translateService.instant(message), 'danger');
                    }),
                )
                .subscribe(),
        );
    }
}

import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { AdminUserFacade } from '@app/admin/store/admin-user';
import { CustomersFacade } from '@app/store/customers';
import { UserFacade } from '@app/store/user';
import { AccountFacade } from '@corelabs/angular-account';
import { errors$ } from '@corelabs/angular-core';
import { MessageService } from '@corelabs/angular-messages';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, filter, merge, mergeMap, take, tap } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class CleanupService {
    private accountFacade = inject(AccountFacade);
    private userFacade = inject(UserFacade);
    private adminUserFacade = inject(AdminUserFacade);
    private customersFacade = inject(CustomersFacade);
    private router = inject(Router);
    private messageService = inject(MessageService);
    private translateService = inject(TranslateService);

    private subscriptions$ = new Subscription();

    constructor() {
        // on logout
        this.subscriptions$.add(
            this.accountFacade.loggedOut$
                .pipe(
                    filter((loggedOut) => loggedOut !== null),
                    tap(() => {
                        this.clear();
                        this.router.navigate(['/auth/sign-in']);
                    }),
                )
                .subscribe(),
        );

        // on user refresh token expire
        this.subscriptions$.add(
            errors$
                .pipe(
                    mergeMap(() =>
                        this.userFacade.loaded$.pipe(
                            take(1),
                            filter((loaded) => loaded === true),
                        ),
                    ),
                    tap(() => {
                        this.messageService.add(this.translateService.instant('logout.message.expired'), 'danger');
                        this.router.navigate(['/logout']);
                    }),
                )
                .subscribe(),
        );

        // on user refresh token expire
        this.subscriptions$.add(
            errors$
                .pipe(
                    mergeMap(() =>
                        this.adminUserFacade.loaded$.pipe(
                            take(1),
                            filter((loaded) => loaded === true),
                        ),
                    ),
                    tap(() => {
                        this.messageService.add(this.translateService.instant('logout.message.expired'), 'danger');
                        this.router.navigate(['/admin/logout']);
                    }),
                )
                .subscribe(),
        );
    }

    private clear() {
        this.userFacade.clear();
        this.adminUserFacade.clear();
        this.customersFacade.clear();
        this.accountFacade.clear();
    }
}

export const footerEnvironment = {
    socialLinks: [
        {
            href: '',
            iconName: <IconType>'mdi:facebook',
            style: 'background-color: #4267B2;',
        },
        {
            href: '',
            iconName: <IconType>'mdi:instagram',
            style: 'background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);',
        },
        {
            href: '',
            iconName: <IconType>'entypo-social:youtube-with-circle',
            style: 'background-color: #FF0000;',
        },
        {
            href: '',
            iconName: <IconType>'ri:linkedin-fill',
            style: 'background-color: #0077b5;',
        },
        {
            href: '',
            iconName: <IconType>'material-symbols:globe-uk',
            backgroundClass: 'bg-emerald-600',
            style: 'background-color: rgb(5 150 105);',
        },
    ],
    footerLinks: [
        { href: '', translationKey: 'info-clause' },
        { href: '', translationKey: 'privacy-policy' },
    ],
    shopLinks: [
        { href: '', name: '' },
        { href: '', name: '' },
    ],
    contact: [
        { heading: '', content: '', translate: { heading: false, content: false } },
        {
            heading: 'KRS:',
            content: '',
            translate: { heading: false, content: false },
        },
        { heading: 'share-capital', content: '5.00 zł', translate: { heading: true, content: false } },
    ],
};

import { Routes, Route } from '@angular/router';
import { ShellUserSuperComponent } from '../components/shell/shell.component';
import { userSuperGuard } from '@app/shells/guards/user-super.guard';
import { userCleanup } from '@app/store/user';

export class ShellUserSuper {
    static childRoutes(routes: Routes): Route {
        return {
            path: '',
            canActivate: [userSuperGuard()],
            canDeactivate: [userCleanup()],
            component: ShellUserSuperComponent,
            children: routes,
        };
    }
}

@let data = data$ | async;
@let features = (features$ | async) ?? [];

<div class="min-h-screen flex flex-col relative">
    <div class="sticky top-0 z-[100]">
        <app-header />
    </div>

    @if (data && data.isUserSuper && data.userSuperInfo && data.userSuperInfo.no && data.userSuperInfo.name && features.includes('sales-person-info')) {
        <p class="text-primary font-semibold text-sm ml-6 md:ml-[4.25rem] pt-3.5">
            {{ data.userSuperInfo.no + ' ' + data.userSuperInfo.name }}
        </p>
    }

    <section class="grow py-6 md:py-12 mx-5 lg:mx-20| 3xl:container 3xl:mx-auto">
        <app-statuses-container>
            <app-ship />
        </app-statuses-container>
        <router-outlet />
    </section>

    <app-footer />
</div>

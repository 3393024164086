import { Component, Input, inject } from '@angular/core';
import { Router } from '@angular/router';
import { NavItem } from '@app/admin/models';
import { UserSwitchService } from '@app/modules/user-switch';
import { UserFacade } from '@app/store/user';

@Component({
    selector: 'app-menu-mobile',
    templateUrl: './menu-mobile.component.html',
    styleUrls: ['./menu-mobile.component.scss'],
})
export class MenuMobileComponent {
    private readonly router = inject(Router);
    private readonly userSwitchService = inject(UserSwitchService);
    private readonly userFacade = inject(UserFacade);

    @Input({ required: true }) menu!: NavItem[];

    features$ = this.userFacade.features$;
    userSwitchEnabled$ = this.userSwitchService.enabled$;

    onLogoutClick(event: MouseEvent) {
        event.preventDefault();
        this.router.navigate(['/logout'], {
            skipLocationChange: true,
        });
    }

    onUserSwitchClick(event: MouseEvent) {
        event.preventDefault();
        this.router.navigate(['/user-switch', 'unset'], { skipLocationChange: true, replaceUrl: true });
    }

    onProfileClick(event: MouseEvent) {
        event.preventDefault();
        this.router.navigate(['/user-profile']);
    }
}

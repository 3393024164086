import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { UserFacade } from '@app/store/user';
import { map } from 'rxjs';

@Component({
    selector: 'app-switch-user',
    templateUrl: './switch-user.component.html',
    styleUrls: ['./switch-user.component.scss'],
})
export class SwitchUserComponent {
    private readonly router = inject(Router);
    private readonly userFacade = inject(UserFacade);

    readonly client$ = this.userFacade.user$.pipe(map((user) => user.client));

    onUserSwitchClick() {
        this.router.navigate(['/user-switch', 'unset'], { skipLocationChange: true, replaceUrl: true });
    }
}
